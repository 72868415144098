/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ModalObsAntifraude from '../ModalObsAntifraude/ModalObsAntifraude.js';
import ModalLiberacaoAntifraude from '../ModalLiberacaoAntifraude/ModalLiberacaoAntifraude.js';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useSelector } from 'react-redux';
import sortFunc from '../../../utils/ordernarColunaInt';

const { SearchBar } = Search;

export default function Antifraude() {
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true,
      formatter: (celula, valor) => {
        return `${valor.rede}${valor.codProduto}`;
      },
    },
    {
      dataField: 'rede',
      text: 'Rede',
      sort: true,
    },
    {
      dataField: 'codProduto',
      text: 'Cód. American Burrs',
      sort: true,
      sortFunc: sortFunc,
    },
    // {
    //   dataField: 'codRevenda',
    //   text: 'Cód. Revenda',
    //   sort: true,
    // },
    {
      dataField: 'produto',
      text: 'Produto',
      sort: true,
    },
    {
      dataField: 'qtdVendida',
      text: 'Qtd. Vendida',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.qtdVendida).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'qtdPontuada',
      text: 'Qtd. Pontuada',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.qtdPontuada).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'liberado',
      text: 'Qtd. Liberado',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.liberado).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'qtdAf',
      text: 'Qtd. AF',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.qtdAf) < 0 ? (
          <span className="f-red">
            {Number(valor.qtdAf).toLocaleString('pt-BR')}
          </span>
        ) : (
          <span>{Number(valor.qtdAf).toLocaleString('pt-BR')}</span>
        );
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'saldoPontos',
      text: 'Saldo Pontos',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.saldoPontos) < 0 ? (
          <span className="f-red">
            {Number(valor.saldoPontos).toLocaleString('pt-BR')}
          </span>
        ) : (
          <span>{Number(valor.saldoPontos).toLocaleString('pt-BR')}</span>
        );
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'observacao',
      text: 'Observação',
      sort: true,
    },
    {
      dataField: 'editarObs',
      isDummyField: true,
      text: 'Editar Observação',
      sort: true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalObs(valor.rede, valor.observacao, valor.codProduto);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
    {
      dataField: 'editarLib',
      isDummyField: true,
      text: 'Editar Liberação',
      sort: true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalLiberacao(valor.rede, valor.liberado, valor.codProduto);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
  ];

  useEffect(() => {
    buscarRedes();
    buscarAntifraude();
  }, []);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  const buscarAntifraude = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/antifraude?rede=${redeSelecionada}`);
    if (resultado) {
      setData(resultado.data.antiFraude);
    }
    setBuscando(false);
  };

  const redesAntifraude = useSelector((state) => {
    return state.autenticacao.redesAntifraude;
  });

  const [mostrarModalObs, setMostrarModalObs] = useState(false);
  const [mostrarModalLiberacao, setMostrarModalLiberacao] = useState(false);
  const [data, setData] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState('');
  const [redesSelect, setRedesSelect] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [rede, setRede] = useState('');
  const [observacao, setObservacao] = useState('');
  const [liberado, setLiberacao] = useState(0);
  const [codProduto, setCodProduto] = useState('');

  const abrirModalObs = (rede, observacao, codProduto) => {
    setRede(rede);
    setObservacao(observacao);
    setCodProduto(codProduto);
    setMostrarModalObs(true);
  };

  const fecharModalObs = () => {
    setMostrarModalObs(false);
    setRede('');
    setObservacao('');
    setCodProduto('');
  };

  const abrirModalLiberacao = (rede, liberado, codProduto) => {
    setRede(rede);
    setLiberacao(liberado);
    setCodProduto(codProduto);
    setMostrarModalLiberacao(true);
  };

  const fecharModalLiberacao = () => {
    setMostrarModalLiberacao(false);
    setRede('');
    setLiberacao('');
    setCodProduto('');
  };

  const atualizarObsTabela = (obs, rede, codProduto) => {
    setData(
      data.map((row) => {
        if (row.rede === rede && row.codProduto === codProduto) {
          row.observacao = obs;
        }
        return row;
      })
    );
  };

  const atualizarLiberacaoTabela = (liberado, rede, codProduto) => {
    setData(
      data.map((row) => {
        if (row.rede === rede && row.codProduto === codProduto) {
          row.liberado = Number(row.liberado) + Number(liberado);
          row.qtdAf =
            Number(row.liberado) +
            Number(row.qtdVendida) -
            Number(row.qtdPontuada);

          row.saldoPontos = Number(row.qtdAf) * Number(row.pontosUnitario);
        }
        return row;
      })
    );
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Antifraude</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) =>
              redesAntifraude.includes(rede.nome) ? (
                <option className="f-red" value={rede.nome} key={rede.nome}>
                  {rede.nome} ⬤
                </option>
              ) : (
                <option value={rede.nome} key={rede.nome}>
                  {rede.nome}
                </option>
              )
            )}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button
            className="btn-busca"
            type="submit"
            onClick={buscarAntifraude}
          >
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalObsAntifraude
        show={mostrarModalObs}
        onHide={fecharModalObs}
        rede={rede}
        observacao={observacao}
        codProduto={codProduto}
        atualizarObsTabela={atualizarObsTabela}
      />
      <ModalLiberacaoAntifraude
        show={mostrarModalLiberacao}
        onHide={fecharModalLiberacao}
        rede={rede}
        liberado={liberado}
        codProduto={codProduto}
        atualizarLiberacaoTabela={atualizarLiberacaoTabela}
      />
    </Container>
  );
}
